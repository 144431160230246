.about {

    h1, h2, p {
        @extend %select;
    }
    
    h1 {
        margin-top: 33rem;
        margin-left: 41rem;
        width: 110rem;
        font-size: 9.6rem;
        font-weight: 500;
    }

    .images {
        margin-top: 18rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        #image-1 {
            background: url('../images/zombie.png') no-repeat center;
            background-size: cover;
            width: 27.8rem;
            height: 58.7rem;
        }

        #image-2 {
            background: url('../images/eye.png') no-repeat center;
            background-size: cover;
            margin: 0 4rem;
            width: 66.3rem;
            height: 73.7rem;
        }

        #image-3 {
            background: url('../images/demon.png') no-repeat center;
            background-size: cover;
            width: 36.4rem;
            height: 59.5rem;
        }
    }

    h2 {
        margin-top: 19rem;
        margin-left: 15rem;
        font-size: 9.6rem;
        font-weight: 600;
    }

    p {
        margin: auto;
        margin-top: 10rem;
        font-size: 3.6rem;
        font-weight: 300;
        width: 132rem;
        text-align: start;

        b {
            font-weight: 400;
        }

        a {
            cursor: pointer;
            font-weight: 400;
            text-decoration: underline;
        }
    }

    #link {
        cursor: pointer;
        transition: all .4s cubic-bezier(0.2, 0.6, 0.2, 1);
        display: block;
        margin: 15rem;
        font-size: 3.6rem;
        font-weight: 700;

        &:hover {
            margin-left: 19rem;
            transition: all .4s cubic-bezier(0.2, 0.6, 0.2, 1);
        }
    }

    @media screen and (max-width: 480px) {
        h1 {
            margin-top: 28rem;
            margin-left: 3rem;
            font-size: 3.6rem;
            width: 23rem;
        }

        .images {
            margin-top: 13rem;
            justify-content: flex-start;

            #image-1 {
                margin-left: 3rem;
                width: 5.5rem;
                height: 11.6rem;
            }

            #image-2 {
                width: 9.8rem;
                height: 10.8rem;
                margin: 0 3rem;
            }

            #image-3 {
                width: 7.9rem;
                height: 12.8rem;
            }
        }

        h2 {
            margin-top: 5rem;
            margin-left: 3rem;
            font-size: 3.6rem;
        }

        p {
            width: 30rem;
            margin-right: 0;
            margin-left: 3rem;
            margin-top: 3rem;
            font-size: 1.6rem;
        }

        #link {
            margin: 3rem;
            font-size: 1.8rem;
            transition: none;
            
            &:hover {
                margin-left: 3rem;
                transition: none;
            }
        }
    }
}