html {
    font-size: calc(100vw / 1920 * 10);
    @media screen and (max-width: 480px) {
        font-size: calc(100vw / 414 * 10);
    }
}


body {
    font-family: "IBM Plex Mono", monospace;
    margin: 0;
    cursor: default;
    @media only screen and (max-width: 480px) {
        width: 100%;
        height: 100%;
    }
}

.black-back {
    background-color: black;
    color: white;
}

* {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    cursor: inherit;
}

h1, h2, h3, p {
    margin: 0;
}

a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
}

%select {
    cursor: text;
    -moz-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;

    &::selection {
        // if opacity is set to 1.0 browsers lower it automatically, 
        // so for it to appear solid set it close to 1
        background-color: rgba($color: black, $alpha: .99);
        color: white;
    }
}