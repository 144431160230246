@font-face {
    font-family: "IBM Plex Mono";
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "IBM Plex Mono";
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "IBM Plex Mono";
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "IBM Plex Mono";
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "IBM Plex Mono";
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}