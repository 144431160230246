.talks-page {
    h1, h2, p {
        @extend %select;
    }
    h1 {
        margin-top: 10rem;
        margin-left: 10rem;
        width: 110rem;
        font-size: 3.6rem;
        font-weight: 500;
    }

    a {
        cursor: pointer;
        transition: all .4s cubic-bezier(0.2, 0.6, 0.2, 1);
        display: block;
        margin: 10rem;
        margin-top: 5rem;
        font-size: 2.2rem;
        font-weight: 700;
        color: #E45826;

        &:hover {
            margin-left: 15rem;
            transition: all .4s cubic-bezier(0.2, 0.6, 0.2, 1);
        }
    }

    @media screen and (max-width: 480px) {
        h1 {
            margin-top: 20rem;
            margin-left: 3rem;
            width: 30rem;
            font-size: 2.2rem;
            font-weight: 500;
        }

        a {
            margin: 3rem;
            font-size: 1.8rem;
            transition: none;
            
            &:hover {
                margin-left: 3rem;
                transition: none;
            }
        }
    }
}