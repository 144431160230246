.header {
    transition: margin .4s cubic-bezier(0.2, 0.6, 0.2, 1);
    font-size: 3.6rem;
    font-weight: 500;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    top: 14rem;
    margin-left: auto;
    margin-right: 10rem;
    width: fit-content;
    cursor: pointer;
    z-index: 999;

    &:hover {
        margin-right: 14rem;
        transition: margin .4s cubic-bezier(0.2, 0.6, 0.2, 1);
    }

    @media screen and (max-width: 480px) {
        font-size: 1.6rem;
        margin-right: 2rem;
        top: 6rem;
        transition: none;
        &:hover {
            margin-right:2rem;
            transition: none;
        }
    }
}