.project-page {
    
    h1, p {
        cursor: text;
        -moz-user-select: text;
        -webkit-user-select: text;
        -ms-user-select: text;
        user-select: text;

        &::selection {
            // if opacity is set to 1.0 browsers lower it automatically, 
            // so for it to appear solid set it close to 1
            background-color: rgba($color: white, $alpha: .99);
            color: black;
        }
    }

    .project-title {
        margin-top: 38rem;
        margin-left: 21rem;

        h1 {
            font-size: 9.6rem;
            font-weight: 600;
        }

        p {
            font-size: 3.6rem;
        }
    }

    .block {
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            margin-top: 11rem;
            font-size: 6.4rem;
            font-weight: 700;
        }

        p {
            margin-top: 14rem;
            width: 87rem;
            font-size: 3.6rem;
            font-weight: 300;
        }
    }

    a {
        transition: all .4s cubic-bezier(0.2, 0.6, 0.2, 1);
        display: block;
        margin: 15rem;
        font-size: 3.6rem;
        font-weight: 700;
        cursor: pointer;

        &:hover {
            margin-left: 19rem;
            transition: all .4s cubic-bezier(0.2, 0.6, 0.2, 1);
        }
    }

    @media screen and (max-width: 480px) {
        .project-title {
            margin-top: 35rem;
            margin-left: 5rem;

            h1 {
                font-size: 3.6rem;
            }

            p {
                font-size: 1rem;
            }
        }

        .block {
            h1 {
                margin-top: 4rem;
                font-size: 2.4rem;
                width: 30rem;
            }

            p {
                margin-top: 3rem;
                width: 30rem;
                font-size: 1.6rem;
            }
        }

        a {
            margin: 5rem;
            font-size: 1.8rem;
            transition: none;
            &:hover {
                margin:5rem;
                transition: none;
            }
        }
    }
}