.entry {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 135rem;


    .exposed {
        font-size: 14.4rem;
        font-weight: 500;
        cursor: pointer;

        @keyframes strike {
            0%   { width : 0; }
            100% { width: 100%; }
        }
        @keyframes strike-back {
            0%   { width : 100%; }
            100% { width: 0; }
        }
        .strike, .strike-back{
            position: relative;
        }
        .strike::after {
            content: ' ';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1rem;
            background: black;
            animation-name: strike;
            animation-duration: .4s;
            animation-timing-function: cubic-bezier(0.2, 0.6, 0.2, 1);
            animation-iteration-count: 1;
            animation-fill-mode: forwards; 
        }

        .strike-back::after {
            content: ' ';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1rem;
            background: black;
            animation-name: strike-back;
            animation-duration: .4s;
            animation-timing-function: cubic-bezier(0.2, 0.6, 0.2, 1);
            animation-iteration-count: 1;
            animation-fill-mode: forwards; 
        }
    }

    .hidden {
        background-color: black;
        color: white;
        font-size: 14.4rem;
        text-align: center;
        cursor: default;

        &:not(:empty) {
            cursor: pointer;
        }
    }

    #about {
        width: 81.4rem;
    }

    #proj {
        width: 95.4rem;
    }

    #contact {
        width: 61.2rem;
    }
}

.mobile-entry {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;

    .exposed{
        margin: auto;

        @keyframes appear-from-bottom {
            0% {opacity: 0;transform: translateX(-100%);}
            100% {opacity: 1;transform: translateX(0%);}	
        }

        .delay-2 {
            animation-delay: .2s;
            -webkit-animation-delay: .2s;
        }
        .delay-4 {
            animation-delay: .4s;
            -webkit-animation-delay: .4s;
        }

        h1 {
            display: block;
            font-size: 7.2rem;
            font-weight: 500;

            -webkit-animation: appear-from-bottom .6s cubic-bezier(0.2, 0.6, 0.2, 1);
            -moz-animation: appear-from-bottom .6s cubic-bezier(0.2, 0.6, 0.2, 1);
            animation: appear-from-bottom .6s cubic-bezier(0.2, 0.6, 0.2, 1);
            -webkit-animation-fill-mode:backwards;
            -moz-animation-fill-mode:backwards;
            animation-fill-mode:backwards;
        }
    }

    .hidden {
        margin: auto;

        h1 {
            display: block;
            font-size: 7.2rem;
            font-weight: 500;
        }

        @keyframes blank-about {
            0%   { height : 0; }
            100% { height: 100%; content: "About"; color: white}
        }
        @keyframes blank-proj {
            0%   { height : 0; }
            100% { height: 100%; content: "Projects"; color: white}
        }
        @keyframes blank-contact {
            0%   { height : 0; }
            100% { height: 100%; content: "Contact"; color: white}
        }

        .blank {
            position: relative;
            width: 100%;
        }

        #abt::after {animation-name: blank-about; content: "About";}

        #prj::after {animation-name: blank-proj; content: "Projects";}

        #cnt::after {animation-name: blank-contact; content: "Contact";}

        .blank::after {
            content: ' ';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 35rem;
            text-align: center;
            background: black;
            animation-duration: .4s;
            animation-timing-function: cubic-bezier(0.2, 0.6, 0.2, 1);
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
    }

    #tap {
        margin-bottom: 4rem;
        font-size: 3.6rem;

        @keyframes bounce {
            from {
              transform: translateY(0);
            }
            to {
              transform: translateY(-.8rem);
            }
        }
        @-webkit-keyframes bounce {
            from {
              transform: translateY(0);
            }
            to {
              transform: translateY(-.8rem);
            }
        }

        -webkit-animation:bounce .5s cubic-bezier(.5, .6, .6, 1) infinite alternate;
		-moz-animation:bounce .5s cubic-bezier(.5, .6, .6, 1) infinite alternate;
		animation:bounce .5s cubic-bezier(.5, .6, .6, 1) infinite alternate;	
    }
}